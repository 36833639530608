import { getCsrfToken, getSession, signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Container, Flex, Text, ThemeProvider } from 'theme-ui';

import { Layout, LoginForm, SEO } from '../components';
import { STRINGS } from '../constants';
import theme from '../theme';

export default function Login({ csrfToken }) {
  const { error } = useRouter().query;

  return (
    <ThemeProvider theme={theme}>
      <Layout isAuth={false}>
        <SEO title={STRINGS.seo.signIn} />
        <Container sx={styles.container}>
          <Flex sx={styles.container.headerBox}>
            <Text variant="heroPrimary" sx={styles.container.headerBox.text}>
              {STRINGS.common.appHeader}
            </Text>
          </Flex>
          <LoginForm csrfToken={csrfToken} signIn={signIn} error={error} />
        </Container>
      </Layout>
    </ThemeProvider>
  );
}

export async function getServerSideProps(context) {
  const { req } = context;
  const session = await getSession({ req });

  if (session) {
    return {
      redirect: { destination: '/' },
    };
  }
  const csrfToken = await getCsrfToken(context);
  return {
    props: {
      csrfToken: csrfToken || null,
    },
  };
}

const styles = {
  container: {
    display: 'flex',
    minHeight: '70vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    mt: '1rem',
    headerBox: {
      alignItems: 'center',
      justifyContent: 'center',
      text: {
        fontSize: ['18px', '20px', '24px', null, '28px', '30px', null, '32px'],
        textAlign: 'center',
      },
    },
  },
};
